const BASE_URL = "https://alexlobera.com"
const BASE_URL_PATH = `${BASE_URL}/.netlify/functions`

const defaultOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
}

export function addContact({ email, name }) {
  return fetch(`${BASE_URL_PATH}/contact`, {
    ...defaultOptions,
    body: JSON.stringify({ email, name }),
  })
}

export function unsubscribe({ email }) {
  return fetch(`${BASE_URL_PATH}/unsubscribe`, {
    ...defaultOptions,
    body: JSON.stringify({ email }),
  })
}
