import React from "react"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
}

export const Button = ({ loading, children, ...rest }: ButtonProps) => (
  <button {...rest} className="news__btn">
    {loading ? <div className="spinner"></div> : children}
  </button>
)
